import React, { Component } from 'react'

class Footer extends Component {
  constructor() {
    super()
    this.state = {}
  }

  componentDidMount() {
    const { isLogistik } = this.props

    const log_title = 'ICS Logistik & Transport GmbH'
    const con_title = 'ICS Container-Logistik GmbH'

    const log_email = 'office@ics-logistik.com'
    const con_email = 'office@ics-container.com'

    this.setState({
      title: isLogistik ? log_title : con_title,
      email: isLogistik ? log_email : con_email
    })
  }

  render() {
    const { isMobile, getPageText } = this.props
    const { title, email } = this.state

    const getText = name => getPageText('footer', name)

    return (
      <div
        style={{
          minHeight: 70,
          padding: 10,
          paddingTop: 10,
          paddingBottom: 10,
          backgroundColor: 'rgba(0, 0, 0, 0.9)',
          color: 'white',
          fontSize: 14,
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          flexWrap: !isMobile ? 'wrap' : 'nowrap',
          justifyContent: !isMobile ? 'space-around' : 'flex-start',
          alignItems: 'center',
          borderColor: '#005499',
          borderStyle: 'solid',
          borderWidth: '5px 0px 0px 0px',
          lineHeight: 1.3
        }}
      >
        <div
          style={{
            paddingTop: isMobile ? 10 : 0,
            paddingBottom: isMobile ? 10 : 0
          }}
        >
          {title && title}
          <br />
          {getText('ceo')}: Hubert Pichler
        </div>
        <div
          style={{
            paddingTop: isMobile ? 10 : 0,
            paddingBottom: isMobile ? 10 : 0
          }}
        >
          Breslauer Str. 49 <br />
          D-83395 Freilassing
        </div>
        <div
          style={{
            paddingTop: isMobile ? 10 : 0,
            paddingBottom: isMobile ? 10 : 0
          }}
        >
          {getText('phone')}: +49 (0)8654 / 4697 - 0<br />
          {getText('fax')}: +49 (0)8654 / 4697 - 19
          <br />
          {getText('email')}:{' '}
          {email && (
            <a style={{ color: '#fff' }} href={`mailto:${email}`}>
              {email}
            </a>
          )}
          <br />
          <div style={{ display: 'flex' }}>
            <a style={{ color: '#fff', textDecoration: 'underline' }} href='./impressum'>
              {getText('about')}
            </a>
            <a style={{ color: '#fff', textDecoration: 'underline', paddingLeft: 5 }} href='./datenschutzerklaerung'>
              {getText('dsgvo')}
            </a>
          </div>
        </div>
      </div>
    )
  }
}

export default Footer
