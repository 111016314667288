import React, { Component } from 'react'
import menuIcon from './Icons/menuIconSmal.png'
import ukIcon from './Icons/uk.png'
import gerIcon from './Icons/ger.png'
import ScrollLock from 'react-scrolllock'
import LazyLoad from 'react-lazyload'


class Header extends Component {
  constructor() {
    super()
    this.state = {}
  }

  componentDidMount() {
    const { isLogistik } = this.props


    const logo = require(isLogistik ? './Media/logLogo2.jpg' : './Media/conLogo1.jpg')
    this.setState({
      logo: logo
    })
  }
  

  getButton = (text, name, link) => {
    const { history } = this.props

    return (
      <div
      
        id={link}
        key={link}
        style={{
          color: history.location.pathname === link ? '#005499' : '#000',
          fontWeight: history.location.pathname === link ? 'bold' : 'normal',
          textAlign: 'center',
          paddingLeft: 2,
          paddingRight: 2,
          letterSpacing: 0.5,
          margin: 15,
          cursor: 'pointer',
          // borderBottom: history.location.pathname === link ? '2px solid #005499' : null,
          fontSize: 17
        }}
        onClick={() => {
          history.push(link)
        }}

      >
        {text}

        {history.location.pathname === link && (
          <div
            style={{
              backgroundColor: '#005499',
              height: 2,
              width: 'calc(100% + 4px)',
              marginLeft: -2,
              animationName: 'menuItem',
              animationDirection: 'normal',
              animationDuration: '500ms'
              
            }}
          />
          
        )}


      </div>
      
    )
  }

  render() {
    const { history, menuOpen, setMenuOpen, menuItems, isLogistik, isMobile, changeLanguage, language, getPageText} = this.props
    const {logo} = this.state

    const getText = name => getPageText('navigation', name)

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          padding: 5,
          paddingLeft: isMobile ? 10 : 50,
          paddingRight: 50,
          height: 65,
          color: '#005499',
          borderColor: '#005499',
          borderStyle: 'solid',
          borderWidth: '0px 0px 5px 0px'
        }}

        
      >
        <LazyLoad once height={70}>
          <img
            style={{ width: isLogistik ? 200 : 88, height: 70, cursor: 'pointer' }}
            src={logo}
            alt=''
            onClick={() => {
              setMenuOpen(false)
              history.push('/')
            }}
          />
        </LazyLoad>

        {!isMobile && (
          <div style={{ display: 'flex' }}>
            <nav>
              <div
                style={{
                  position: 'absolute',
                  right: 130,
                  width: 900,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  paddingTop: 8,
                  overflow: 'hidden'
                }}
              >
                
                {menuItems && menuItems.map(item => this.getButton(item.name, item.name, item.link))}
              </div>
            </nav>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                position: 'absolute',
                right: 10,
                paddingTop: 13,
                width: 100,
                overflow: 'hidden',
                color: '#000'
              }}
            >

              <div
                style={{
                  display: 'flex',
                  marginBottom: 5,
                  cursor: 'pointer',
                  textDecoration: language === 'en' ? 'underline' : ''
                }}
                onClick={() => changeLanguage('en')}
              >
                <div
                  style={{
                    width: 15,
                    height: 15,
                    marginRight: 10,
                    backgroundImage: `url(${ukIcon})`,
                    backgroundSize: 'cover'
                  }}
                  
                />
                
                <div>{getText('english')}</div>
              </div>
              <div
                style={{
                  display: 'flex',
                  cursor: 'pointer',
                  textDecoration: language === 'de' ? 'underline' : ''
                }}
                onClick={() => changeLanguage('de')}
              >
                <div
                  style={{
                    width: 15,
                    height: 15,
                    marginRight: 10,
                    backgroundImage: `url(${gerIcon})`,
                    backgroundSize: 'cover'
                  }}
                />
                <div>{getText('german')}</div>
              </div>
            </div>
          </div>
        )}
        {isMobile && (
          <div style={{ position: 'absolute', right: 20, top: 20, zIndex: 100 }}>
            <img
              alt=''
              src={menuIcon}
              style={{ animationDuration: '0.2s', width: 30, height: 30 }}
              ref={component => (this.menuIcon = component)}
              onClick={() => {
                setMenuOpen(!menuOpen)
                this.menuIcon.style.animationName = null
                // eslint-disable-next-line
                this.menuIcon.offsetWidth
                this.menuIcon.style.animationName = 'menuArrow'
              }}
            />
          </div>
        )}
        {isMobile && menuOpen && (
          <div>
            <div
              style={{
                position: 'absolute',
                left: 0,
                width: '100%',
                top: 80,
                height: '100%',
                zIndex: 90
              }}
              onClick={() => setMenuOpen(false)}
            />
            <div
              style={{
                position: 'absolute',
                right: 0,
                top: 80,
                width: '100%',
                backgroundColor: 'rgba(0, 84, 153, 0.98)',
                zIndex: 99,
                paddingTop: 25,
                color: 'white',
                fontSize: 20,
                animationName: 'menuOpen',
                animationDuration: '0.2s'
              }}
            >
              <ScrollLock />
              <nav style={{ marginLeft: 40 }}>
                {menuItems &&
                  menuItems.map(item => (
                    <div
                      style={{ paddingBottom: 25 }}
                      onClick={() => {
                        setMenuOpen(false)
                        history.push(item.link)
                      }}
                      key={item.name}
                    >
                      {item.name}
                    </div>
                  ))}
              </nav>
              <div
                style={{
                  display: 'flex',
                  position: 'absolute',
                  right: 10,
                  bottom: 20
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    marginRight: 20,
                    cursor: 'pointer',
                    textDecoration: language === 'en' ? 'underline' : ''
                  }}
                  onClick={() => changeLanguage('en')}
                >
                  <div
                    style={{
                      width: 25,
                      height: 25,
                      backgroundImage: `url(${ukIcon})`,
                      backgroundSize: 'cover'
                    }}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    cursor: 'pointer',
                    marginRight: 10,
                    textDecoration: language === 'de' ? 'underline' : ''
                  }}
                  onClick={() => changeLanguage('de')}
                >
                  <div
                    style={{
                      width: 25,
                      height: 25,
                      backgroundImage: `url(${gerIcon})`,
                      backgroundSize: 'cover'
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default Header
