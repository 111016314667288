import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import registerServiceWorker from './registerServiceWorker'
import { BrowserRouter as Router } from 'react-router-dom'
import { Helmet } from 'react-helmet'

const isLogistik = window.location.href.indexOf('ics-logistik.com') !== -1
//const isLogistik = true
const title = isLogistik ? 'ICS Logistik & Transport' : 'ICS Container-Logistik'

const keywords = isLogistik
  ? 'spedition,logistik,unternehmen,container,transporte,freilassing,international,deutschland,italien,tschechien'
  : 'spedition,logistik,unternehmen,container,transporte,freilassing,international,deutschland,münchen,muc,salzburg,cts,hamburg,hbg'

const description = isLogistik
  ? 'ICS Logistik & Transport GmbH Ihr zuverlässiges Logistikunternehmen für internationale Transporte. Spedition aus Freilassing, Bayern. Deutschland, Italien, Tschechien...'
  : 'ICS Logistik & Transport GmbH Ihr zuverlässiges Logistikunternehmen für internationale Container Transporte. Spedition aus Freilassing, Bayern.'

ReactDOM.render(
  <div>
    <Helmet>
      <meta name='keywords' content={keywords} />
      <meta name='description' content={description} />
      <title>{title}</title>
    </Helmet>
    <Router>
      <App isLogistik={isLogistik} />
    </Router>
  </div>,
  document.getElementById('root')
)
registerServiceWorker()
