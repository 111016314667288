import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Route, Switch } from "react-router-dom";
import Loadable from "react-loadable";
import "babel-polyfill";
import Analytics from "rmw-shell/lib/containers/Analytics/Analytics";
import getTranslation from "./languages/getTranslation";
import Header from "./Header";
import Footer from "./Footer";

const Loading = () => <div style={{ height: "100vh" }} />;

const Startseite = Loadable({
  loader: () => import("./Pages/Startseite"),
  // loader: () => import('./Pages/Startseite_video'),
  loading: Loading,
});
const Geschichte = Loadable({
  loader: () => import("./Pages/Geschichte"),
  loading: Loading,
});
const Serviceangebot = Loadable({
  loader: () => import("./Pages/Serviceangebot"),
  loading: Loading,
});
const Lagerlogistik = Loadable({
  loader: () => import("./Pages/Lagerlogistik"),
  loading: Loading,
});
const Mitarbeiter = Loadable({
  loader: () => import("./Pages/Mitarbeiter"),
  loading: Loading,
});
const Karriere = Loadable({
  loader: () => import("./Pages/Karriere"),
  loading: Loading,
});
const Kontakt = Loadable({
  loader: () => import("./Pages/Kontakt"),
  loading: Loading,
});
const Impressum = Loadable({
  loader: () => import("./Pages/Impressum"),
  loading: Loading,
});
const Datenschutzerklaerung = Loadable({
  loader: () => import("./Pages/Datenschutzerklaerung"),
  loading: Loading,
});
const Dieselpreis = Loadable({
  loader: () => import("./Pages/Dieselpreis"),
  loading: Loading,
});
const PageNotFound = Loadable({
  loader: () => import("./Pages/PageNotFound"),
  loading: Loading,
});
const Consulting = Loadable({
  loader: () => import("./Pages/Consulting"),
  loading: Loading,
});
const Transport = Loadable({
  loader: () => import("./Pages/Transport"),
  loading: Loading,
});
const Towing = Loadable({
  loader: () => import("./Pages/Towing"),
  loading: Loading,
});
const Washing = Loadable({
  loader: () => import("./Pages/Washing"),
  loading: Loading,
});
const Train = Loadable({
  loader: () => import("./Pages/Train"),
  loading: Loading,
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: "0",
      height: "0",
      menuOpen: false,
      scrolled: false,
      language: "de",
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);

    window.addEventListener("scroll", () => {
      this.setState({ scrolled: true });
    });
  }

  
  render() {
    const { isLogistik, parallaxWidth, history } = this.props;

    const companyName = isLogistik ? "log" : "con";
    const trackingID = isLogistik ? "UA-147121425-2" : "UA-147121425-3"
    const getPageText = (page, name) =>
      getTranslation(this.state.language, page, companyName, name);
    
    const logMenuItems = [
      {

        name: getPageText("navigation", "frontpage_name"),
        link: getPageText("navigation", "frontpage_link"),
      },
      {
        name: getPageText("navigation", "history_name"),
        link: getPageText("navigation", "history_link"),
      },
      {
        name: getPageText("navigation", "services_name"),
        link: getPageText("navigation", "services_link"),
      },
      {
        name: getPageText("navigation", "employees_name"),
        link: getPageText("navigation", "employees_link"),
      },
      {
        name: getPageText("navigation", "career_name"),
        link: getPageText("navigation", "career_link"),
      },
      {
        name: getPageText("navigation", "contact_name"),
        link: getPageText("navigation", "contact_link"),
      },
    ];
    const conMenuItems = [
      {
        name: getPageText("navigation", "frontpage_name"),
        link: getPageText("navigation", "frontpage_link"),
      },
      {
        name: getPageText("navigation", "history_name"),
        link: getPageText("navigation", "history_link"),
      },
      {
        name: getPageText("navigation", "services_name"),
        link: getPageText("navigation", "services_link"),
      },
      {
        name: getPageText("navigation", "employees_name"),
        link: getPageText("navigation", "employees_link"),
      },
      {
        name: getPageText("navigation", "career_name"),
        link: getPageText("navigation", "career_link"),
      },
      {
        name: getPageText("navigation", "contact_name"),
        link: getPageText("navigation", "contact_link"),
      },
    ];

    if (window.updateAvailable) {
      window.location.reload(true);
    }

    const changeLanguage = (language) => {
      this.setState({ language });
    };
    const setMenuOpen = (open) => {
      this.setState({ menuOpen: open });
    };

    const isMobile = this.state.width < 1120; //1120 because of the menu

    const allprops = {
      isMobile: isMobile,
      isLogistik: isLogistik,
      parallaxWidth: parallaxWidth,
      width: this.state.width,
      height: this.state.height,
      menuOpen: this.state.menuOpen,
      changeLanguage,
      language: this.state.language,
      getPageText,
    };

    return (
      <div
        style={{
          fontFamily: "Arial, Helvetica, sans-serif",
        }}
      >
        <Header
          {...this.props}
          {...allprops}
          setMenuOpen={setMenuOpen}
          menuItems={isLogistik ? logMenuItems : conMenuItems}
        />
        <main>
          <Switch>
            <Route
              exact
              path="/"
              render={(props) => (
                <Startseite
                  {...props}
                  {...allprops}
                  scolled={this.state.scrolled}
                />
              )}
            />
            
            <Route
              exact
              path="/(geschichte|history)/"
              render={(props) => <Geschichte {...props} {...allprops} />}
            />
            <Route
              exact
              path="/(serviceangebot|services)/"
              render={(props) => <Serviceangebot {...props} {...allprops} />}
            />
            {isLogistik && (
              <Route
                exact
                path="/(lagerlogistik|storage)/"
                render={(props) => <Lagerlogistik {...props} {...allprops} />}
              />
            )}
            <Route
              exact
              path="/(mitarbeiter|employees)/"
              render={(props) => <Mitarbeiter {...props} {...allprops} />}
            />
            <Route
              exact
              path="/(karriere|career)/"
              render={(props) => <Karriere {...props} {...allprops} />}
            />
            <Route
              exact
              path="/impressum"
              render={(props) => <Impressum {...props} {...allprops} />}
            />
            <Route
              exact
              path="/datenschutzerklaerung"
              render={(props) => (
                <Datenschutzerklaerung {...props} {...allprops} />
              )}
            />
            {isLogistik && (
              <Route
                exact
                path="/dieselpreis"
                render={(props) => <Dieselpreis {...props} {...allprops} />}
              />
            )}
            <Route
              exact
              path="/consulting"
              render={(props) => <Consulting {...props} {...allprops} />}
            />

            <Route
              exact
              path="/transport"
              render={(props) => <Transport {...props} {...allprops} />}
            />
            <Route
              exact
              path="/towing"
              render={(props) => <Towing {...props} {...allprops} />}
            />
            <Route
              exact
              path="/washing"
              render={(props) => <Washing {...props} {...allprops} />}
            />
            <Route
              exact
              path="/train"
              render={(props) => <Train {...props} {...allprops} />}
            />
            <Route
              exact
              path="/(kontakt|contact)/"
              render={(props) => <Kontakt {...props} {...allprops} />}
            />
            <Route
              render={(props) => <PageNotFound {...props} {...allprops} />}
            />
          </Switch>
        </main>
        <Analytics
          isOptOut={true}
          trackingID={trackingID}
          handlePolicyClick={() => history.push("/datenschutzerklaerung")}
          acceptLabel="Akzeptieren"
          declineLabel="Verweigern"
          policyLabel="Datenschutz"
          dialogMessage="Diese Seite nutzt Cookies und Website Tracking-Technologien von Dritten, um ihre Dienste anzubieten, und stetig zu verbessern. Ich bin damit einverstanden und kann meine Einwilligung jederzeit mit Wirkung für die Zukunft widerrufen oder ändern."
        />

        <Footer {...this.props} {...allprops} />
      </div>
    );
  }
}

export default withRouter(App);
